<template>
  <div class="top">
    <div class="topTop">
      首句欢迎语
      <el-switch @change="welcomeChange" style="display: block" v-model="firstWelcome"> </el-switch>
    </div>
    <div class="topBot">
      当买家发送的第一条消息分配到人工接待时，会发送这个回复。相同内容一天内只会对一个买家发送一次。
    </div>
  </div>
  <div class="mid">
    <div class="yuan"></div>
    回复内容(若都勾选则自动回复文字消息+微信二维码名片+常见问题)
  </div>
  <div class="footer">
    <Phone type="welcome" :obj="welcome" :cardUrl="cardUrl"></Phone>
    <div class="footerRight">
      <div class="sendTxtMsg">
        <el-checkbox :disabled="!firstWelcome" v-model="welcome.sendTxtMsg.showflag"
          >发送文字消息</el-checkbox
        >
        <div class="content">
          <div class="leftTitle">回复内容:</div>
          <div class="rightContent">
            <div class="txt" v-if="!updateFlag && welcome.sendTxtMsg.content">
              {{ welcome.sendTxtMsg.content }}
            </div>
            <ChatInputBox
              v-if="updateFlag"
              :chatData="chatData"
              @getChatData="getChatData"
            ></ChatInputBox>
            <!-- <div class="updateTxt" v-if="updateFlag">
              <el-input
                ref="sendMsgUpdate"
                :spellcheck="false"
                type="textarea"
                placeholder="请输入内容"
                v-model="updateTxt"
                maxlength="500"
                show-word-limit
                @blur="chatContentChange"
              >
              </el-input>
              <div class="expression">
                <el-popover
                  class="addSome"
                  v-model:visible="expressionFlag"
                  width="300px"
                  trigger="click"
                >
                  <div>
                    <div class="classStyle">
                      <span
                        @click="expressionListActive = index"
                        v-for="(item, index) in expressionList"
                        :key="index"
                        :style="{
                          background: expressionListActive == index ? '#e7e7e7' : ''
                        }"
                        >{{ item.name }}</span
                      >
                    </div>
                    <div
                      class="emojisList"
                      v-for="(item, index) in expressionList"
                      :key="index"
                      :style="{
                        display: expressionListActive == index ? 'block' : 'none'
                      }"
                    >
                      <div>
                        <span
                          @click="insertEmojis(item1)"
                          v-for="(item1, index1) in item.list"
                          :key="index1"
                          >{{ item1 }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <template #reference>
                    <i class="iconfont icon-biaoqing"></i>
                  </template>
                </el-popover>
              </div>
            </div> -->
            <div v-if="!updateFlag" class="update" @click="updateSendMsg">编辑</div>
            <div v-else class="updateRes">
              <div @click="updateSuccess" class="custBtnPri">保存</div>
              <div @click="updateFlag = false" class="custBtn">取消</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sendCommonProblem">
        <el-checkbox :disabled="!firstWelcome" v-model="welcome.sendCommonProblem.showflag"
          >发送常见问题</el-checkbox
        >
        <div class="problemList">
          <div class="problemListLeft">问题列表:</div>
          <div class="problemListRight">
            <template v-for="(item, index) in welcome.sendCommonProblem.list" :key="index">
              <div>{{ item.asking }}</div>
            </template>
            <div class="txtTips">
              回复内容等详细设置请前往常见问题页设置设置常见问题<span
                @click="$router.push('/autoReply/serviceReply/faq')"
                style="margin-left: 10px"
                class="textBtn"
                >设置常见问题</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="sendGoods">
        <el-checkbox :disabled="!firstWelcome" v-model="welcome.sendGoods.showflag"
          >发送商品推荐</el-checkbox
        >
        <div class="txtTips">
          <p>
            当识别买家在进行售前咨询时，会结合用户行为和店铺上新、热销、活动等维度发送商品推荐。
          </p>
          <p>注：小程序、公众号渠道不支持该功能。（左图仅为商品推荐示例，实际推荐以线上为准）</p>
        </div>
      </div>
      <div class="wxCode">
        <el-checkbox :disabled="!firstWelcome" v-model="welcome.wxCode.showflag"
          >发送微信二维码名片</el-checkbox
        >
        <div class="txtTips">
          系统自动发送微信二维码名片，引导买家添加客服微信，提升加粉效率
          <span @click="setQrcode" style="margin-left: 10px" class="textBtn">配置微信名片</span>
        </div>
      </div>
    </div>
  </div>
  <el-dialog title="配置客服微信名片" v-model="setWxCodeFlag" width="35%" @close="handleClose">
    <div class="codeBox">
      <div class="showCode" ref="cardBox">
        <div class="nickname">{{ cloneConfig.client_nickname }}</div>
        <div class="storeName">{{ cloneConfig.shop_name }}</div>
        <div class="avatat">
          <img v-if="cloneConfig.client_avater_64" :src="cloneConfig.client_avater_64" alt="" />
          <div v-else>客服头像</div>
        </div>
        <div class="qrcode">
          <img v-if="cloneConfig.client_qrcode_64" :src="cloneConfig.client_qrcode_64" alt="" />
          <div>二维码区域</div>
        </div>
        <div class="botTips">长按或扫码加我微信，随时聊一聊</div>
      </div>
      <div class="setBox">
        <el-form ref="form" :rules="rules" :model="cloneConfig" label-width="150px">
          <el-form-item label="微信名片名称：" prop="name">
            <el-input
              maxlength="13"
              show-word-limit
              size="small"
              v-model="cloneConfig.name"
            ></el-input>
          </el-form-item>
          <el-form-item label="客服昵称：" prop="client_nickname">
            <el-input
              maxlength="10"
              show-word-limit
              size="small"
              v-model="cloneConfig.client_nickname"
            ></el-input>
          </el-form-item>
          <el-form-item label="上传客服头像：" prop="client_avater">
            <el-upload
              class="avatar-uploader"
              :action="baseurl + api.addImage"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              name="image"
              :headers="headers"
            >
              <img
                v-if="cloneConfig.client_avater"
                :src="cloneConfig.client_avater"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传二维码：" prop="client_qrcode">
            <el-upload
              class="avatar-uploader"
              :action="baseurl + api.addImage"
              :show-file-list="false"
              :on-success="handleQrcodeSuccess"
              :before-upload="beforeAvatarUpload"
              name="image"
              :headers="headers"
            >
              <img
                v-if="cloneConfig.client_qrcode"
                :src="cloneConfig.client_qrcode"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p style="font-size: 12px" class="txtTips">仅支持jpg、png两种格式, 大小不超过500KB</p>
          </el-form-item>
        </el-form>
        <div @click="generateCard" style="align-self: flex-end" class="custBtnPri">生成名片</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, reactive, nextTick, watch, inject, resolveComponent } from 'vue'
import { baseurl } from '../../../util/baseUrl'
import { post, uploadFile } from '../../../util/axios'
import { api } from '../../../util/api'
import Phone from '@/components/phone.vue'
import ChatInputBox from '@/components/chatInputBox'
import { dataURItoBlob, changeBase64 } from '@/util/common.js'
import { ElMessage } from 'element-plus'
import html2canvas from 'html2canvas'
import _ from 'lodash'
export default {
  components: {
    Phone,
    ChatInputBox
  },
  setup() {
    const headers = ref({
      'access-token': sessionStorage.getItem('token')
    })
    const firstWelcome = ref(true)
    // const sendMsgUpdate = ref(null)
    let replyInfo = inject('replyInfo')
    const commonProblemList = inject('commonProblemList')
    firstWelcome.value = replyInfo.value.first_welcome_open == '1' ? true : false
    const welcome = reactive({
      sendTxtMsg: {
        showflag: replyInfo.value.first_welcome_data.text_open == '1' ? true : false,
        content: replyInfo.value.first_welcome_data.text_value
      },
      sendCommonProblem: {
        showflag: replyInfo.value.first_welcome_data.common_problem == '1' ? true : false,
        list: commonProblemList.value
      },
      sendGoods: {
        showflag: replyInfo.value.first_welcome_data.goods_recommend == '1' ? true : false
      },
      wxCode: {
        showflag: replyInfo.value.first_welcome_data.wechat_card == '1' ? true : false
      }
    })
    let cloneList = reactive({
      a: JSON.parse(JSON.stringify(welcome.sendCommonProblem.list))
    })
    watch(
      welcome,
      (val) => {
        if (val.sendCommonProblem.list.toString() == cloneList.a.toString()) {
          post(api.setAutomaticReply, {
            first_welcome_json: {
              text_open: val.sendTxtMsg.showflag ? 1 : 0,
              text_value: val.sendTxtMsg.content,
              common_problem: val.sendCommonProblem.showflag ? 1 : 0,
              goods_recommend: val.sendGoods.showflag ? 1 : 0,
              wechat_card: val.wxCode.showflag ? 1 : 0
            }
          }).then((res) => {
            if (res.code == 0) {
              ElMessage.success('修改成功')
              replyInfo.value.first_welcome_data.text_open = val.sendTxtMsg.showflag ? 1 : 0
              replyInfo.value.first_welcome_data.text_value = val.sendTxtMsg.content
              replyInfo.value.first_welcome_data.common_problem = val.sendCommonProblem.showflag
              replyInfo.value.first_welcome_data.goods_recommend = val.sendGoods.showflag
              replyInfo.value.first_welcome_data.wechat_card = val.wxCode.showflag
            } else {
              ElMessage.error(`${res.msg}`)
            }
          })
        }
        cloneList.a = JSON.parse(JSON.stringify(val.sendCommonProblem.list))
      },
      {
        deep: true
      }
    )

    const updateFlag = ref(false)
    const chatData = ref('')
    const getChatData = (val) => {
      chatData.value = val
    }
    // 点击修改欢迎语
    const updateSendMsg = () => {
      chatData.value = welcome.sendTxtMsg.content
      updateFlag.value = true
    }
    // 完成修改
    const updateSuccess = () => {
      welcome.sendTxtMsg.content = chatData.value
      if (!welcome.sendTxtMsg.content) {
        welcome.sendTxtMsg.showflag = false
      }
      updateFlag.value = false
    }
    // 是否开始欢迎语句
    const welcomeChange = (val) => {
      replyInfo.value.first_welcome_open = val ? 1 : 0
      post(api.setAutomaticReply, {
        first_welcome_open: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const wxQrCodeConfig = ref({})
    // base64路径
    const cloneConfig = ref({})
    const rules = ref({})
    rules.value = {
      name: [{ required: true, message: '请输入微信名片名称', trigger: 'blur' }],
      client_nickname: [{ required: true, message: '请输入客服昵称', trigger: 'blur' }],
      client_avater: [{ required: true, message: '请上传客服头像', trigger: 'blur' }],
      client_qrcode: [{ required: true, message: '请上传二维码', trigger: 'blur' }]
    }
    // 已经生成的名片路径
    const cardUrl = ref('')
    // 获取微信配置
    post(api.getWxCard).then((res) => {
      if (res.code == 0) {
        wxQrCodeConfig.value = res.result
        if (wxQrCodeConfig.value.client_avater) {
          let a =
            wxQrCodeConfig.value.client_avater.substr(
              wxQrCodeConfig.value.client_avater.lastIndexOf('.') + 1
            ) === 'png'
              ? 'data:image/png;base64,'
              : 'data:image/jpeg;base64'
          wxQrCodeConfig.value.client_avater_64 = a + wxQrCodeConfig.value.client_avater_64
        }
        if (wxQrCodeConfig.value.client_qrcode) {
          let b =
            wxQrCodeConfig.value.client_qrcode.substr(
              wxQrCodeConfig.value.client_qrcode.lastIndexOf('.') + 1
            ) === 'png'
              ? 'data:image/png;base64,'
              : 'data:image/jpeg;base64'
          wxQrCodeConfig.value.client_qrcode_64 = b + wxQrCodeConfig.value.client_qrcode_64
        }
        cardUrl.value = wxQrCodeConfig.value.images
      }
    })
    const setWxCodeFlag = ref(false)
    // 设置客服微信名片
    const setQrcode = () => {
      cloneConfig.value = _.cloneDeep(wxQrCodeConfig.value)
      setWxCodeFlag.value = true
    }
    // 上传成功后拿到线上地址
    const handleAvatarSuccess = (res, file) => {
      changeBase64(file.raw, cloneConfig, 0)
      cloneConfig.value.client_avater = res.result.path
    }
    // 上传成功后拿到线上地址
    const handleQrcodeSuccess = (res, file) => {
      changeBase64(file.raw, cloneConfig, 1)
      cloneConfig.value.client_qrcode = res.result.path
    }
    // 检测上传图片是否满足要求
    const beforeAvatarUpload = (file) => {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 < 500
      if (!isJPG) {
        ElMessage.error('上传头像图片只能是 JPG和PNG 格式!')
      }
      if (!isLt2M) {
        ElMessage.error('上传头像图片大小不能超过 500K!')
      }
      return isJPG && isLt2M
    }
    const form = ref()
    const cardBox = ref()

    // 点击生成名片
    const generateCard = () => {
      form.value.validate((valid) => {
        if (valid) {
          html2canvas(cardBox.value, { useCORS: true }).then((canvas) => {
            let res = canvas.toDataURL('image/png')
            let blod = dataURItoBlob(res)
            let files = new window.File([blod], 'wxCard.png', { type: 'image/png' })
            let formData = new FormData()
            formData.append('image', files)
            uploadFile(api.addImage, formData)
              .then((res) => {
                cardUrl.value = res.result.path
                return res
              })
              .then((res) => {
                if (res.code == 0) {
                  return post(api.setCard, {
                    name: cloneConfig.value.name,
                    client_nickname: cloneConfig.value.client_nickname,
                    client_avater: cloneConfig.value.client_avater,
                    client_qrcode: cloneConfig.value.client_qrcode,
                    images: res.result.path
                  })
                } else {
                  ElMessage.error(`${res.msg}`)
                }
              })
              .then((res) => {
                if (res.code == 0) {
                  ElMessage.success('名片生成成功')
                  wxQrCodeConfig.value = cloneConfig.value
                  setWxCodeFlag.value = false
                } else {
                  ElMessage.error(`${res.msg}`)
                }
              })
          })
        }
      })
    }
    const handleClose = () => {}
    return {
      cardBox,
      headers,
      baseurl,
      api,
      firstWelcome,
      welcome,
      chatData,
      // expressionList,
      getChatData,
      // expressionFlag,
      // expressionListActive,
      // insertEmojis,
      // chatContentChange,
      updateSuccess,
      // sendMsgUpdate,
      updateSendMsg,
      welcomeChange,
      updateFlag,
      setWxCodeFlag,
      setQrcode,
      handleClose,
      cloneConfig,
      rules,
      handleAvatarSuccess,
      handleQrcodeSuccess,
      beforeAvatarUpload,
      generateCard,
      form,
      cardUrl
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 20px;
  background-color: #f7f8fa;
  .topTop {
    display: flex;
    align-items: center;
    font-size: 15px;
    .el-switch {
      margin-left: 10px;
    }
  }
  .topBot {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
  }
}
.mid {
  margin-top: 10px;
  padding: 15px 20px;
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  .yuan {
    margin-right: 5px;
    width: 6px;
    height: 6px;
    background: #409eff;
    border-radius: 50%;
  }
}
.footer {
  margin-top: 23px;
  margin-bottom: 80px;
  display: flex;
  align-items: flex-start;
  .footerRight {
    margin-left: 34px;
    width: calc(100% - 409px);
    :deep(.el-checkbox) {
      :depp(.el-checkbox__label) {
        color: #333 !important;
      }
    }
    .content {
      margin-top: 17px;
      display: flex;
      .leftTitle {
        margin-left: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        line-height: 1.5;
      }
      .txt {
        line-height: 1.5;
        margin-bottom: 14px;
        white-space: pre-wrap;
      }
      .update {
        color: #409eff;
        user-select: none;
        line-height: 1.5;
        cursor: pointer;
      }
      .updateRes {
        display: flex;
        margin-top: 15px;
        & > div {
          margin-right: 10px;
        }
      }
    }
  }
}
.sendCommonProblem {
  margin-top: 20px;
  .problemList {
    display: flex;
    margin-left: 24px;
    margin-top: 17px;
    .problemListRight {
      margin-left: 10px;
      & > div {
        margin-bottom: 12px;
      }
    }
  }
}
.sendGoods {
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
    p {
      font-size: 12px !important;
    }
  }
}
.wxCode {
  margin-top: 20px;
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
  }
}
.codeBox {
  display: flex;
  .showCode {
    flex: 0 0 200px;
    height: 358px;
    background-size: 200px 358px;
    flex-shrink: 0;
    background-image: url('../../../assets/images/qrcode_back_card.png');
    margin-right: 20px;
    position: relative;
    .nickname {
      position: absolute;
      color: #333;
      font-size: 13px;
      left: 17px;
      top: 66px;
    }
    .storeName {
      position: absolute;
      color: #999;
      font-size: 12px;
      left: 17px;
      top: 84px;
      transform: scale(0.83);
      transform-origin: 0;
    }
    .avatat {
      position: absolute;
      color: #999;
      font-size: 12px;
      left: 156px;
      top: 66px;
      background: #f7f7f7;
      width: 27px;
      height: 27px;
      border: 1px dashed #d8d8d8;
      border-radius: 2px;
      white-space: normal;
      img {
        width: 100%;
        height: 100%;
      }
      & > div {
        transform: scale(0.63);
        position: relative;
        top: -2px;
        line-height: 14px;
        white-space: normal;
      }
    }
    .qrcode {
      position: absolute;
      color: #999;
      font-size: 12px;
      left: 31px;
      top: 150px;
      background: #f7f7f7;
      width: 138px;
      height: 138px;
      border: 1px dashed #d8d8d8;
      text-align: center;
      line-height: 138px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .botTips {
      position: absolute;
      color: #fff;
      bottom: 20px;
      left: 25px;
      font-size: 12px;
      white-space: nowrap;
      transform: scale(0.83);
      transform-origin: 0;
    }
  }
  .setBox {
    flex: 1;
    min-width: 400px;
    display: flex;
    flex-direction: column;

    :deep(.avatar-uploader) {
      line-height: normal;
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }
      .avatar {
        width: 50px;
        height: 50px;
        display: block;
      }
    }
  }
}
</style>
